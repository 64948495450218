$('.js-office-hero-toggle').click(function(e) {

    var dropDown = $(this).closest('.js-office-hero-toggle').next('ul');

    if ($(this).hasClass('active')) {
        $(this).removeClass('active').attr("aria-expanded","false");

    } else {
        $(this).addClass('active').attr("aria-expanded","true");;
    }

    dropDown.stop(false, true).slideToggle();

    e.preventDefault();
});
