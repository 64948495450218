let links = $('a[href^="#"]:not(.has-dropdown)');
let urlPage = window.location.href;
let idLink = $('#' + urlPage.split('#')[1]);


if (links.length) {
    $('.o-wrapper-body').on('click', 'a[href^="#"]:not(.has-dropdown)', function () {
        let targetLink = $(this).attr('href');
        if (screen && screen.width < 701) {
            $('html, body').animate({

                scrollTop: $(targetLink).offset().top - siteHeader.outerHeight() - 45
            });
        }else {
            $('html, body').animate({

                scrollTop: $(targetLink).offset().top - siteHeader.outerHeight() - 35
            });
        }
    
    });
}

if (idLink.length) {
    $(window).on('load', function () {
        
        if (screen && screen.width < 701) {
            $('html, body').animate({
            
                scrollTop: idLink.offset().top - siteHeader.outerHeight() - 45
            });
        }else {
            $('html, body').animate({
            
                scrollTop: idLink.offset().top - siteHeader.outerHeight() - 35
            });
        }
    });
}