let menuMobileLinkDropdown = $('.js-menu-mobile-link-dropdown');
let menuMobileOpenButton = $('.js-main-nav-control');
let menuMobileCloseButton = $('.js-menu-mobile-close');
let menuMobile = $('.c-site-header__menu-mobile');

/** 
 * Menu mobile
 */

function toggleMenuMobileDropdown(event, $this) {
    event.preventDefault();
    //console.log($this);
    $this.toggleClass('is-active');
    $this.next('.c-menu-mobile-nav__sublist-wrapper').slideToggle();

    // if($('.js-menu-mobile-link-dropdown.is-active')){
    //     $('.js-menu-mobile-link-dropdown').attr('aria-expanded','true')
        
    // }
}

function openMenuMobile() {
    menuMobile.addClass('is-open');
    trapFocus(menuMobile);

}

function closeMenuMobile() {
    menuMobile.removeClass('is-open');
}

function activarControlTeclado(el) {
    jQuery(el).closest('.c-menu-mobile-nav__item').find("a").each(function(indice, element) {
        jQuery(element).off("keyup");
        jQuery(element).on('keyup', function(e) {
            if (e.key == "Escape") {
                e.preventDefault();
                //e.stopPropagation();
                var triggerLink = jQuery(this).closest('.c-menu-mobile-nav__item').find("a.is-active")
                toggleMenuMobileDropdown(e, triggerLink);
                triggerLink[0].focus();
            }
        });
    });
    /*
    jQuery(document).on('keydown', function(e) {
        if (e.key == "Escape") {
            e.preventDefault();
            //e.stopPropagation();
            console.log("cerrar submenu");
            toggleMenuMobileDropdown(e, el);
        }
    });
    */
}

function quitarControlTeclado() {
    jQuery(".c-menu-mobile-nav__item a").each(function(indice, el) {
        jQuery(el).off("keyup");
    });
}
$(function() {
    menuMobileOpenButton.on({
        'mousedown': function(event) {
            if (event.button == 0) {
                event.preventDefault();
                openMenuMobile();
                menuMobileCloseButton[0].focus();
                menuMobileOpenButton.attr('aria-expanded','true')
            }
        },
        'keydown': function(event) {
            console.log(event.key);
            if (event.key == 'Enter' || event.key === ' ') {
                event.preventDefault();
                openMenuMobile();
                console.log("abrir menu");
                menuMobileCloseButton[0].focus();
                // menuMobileOpenButton.attr('aria-expanded','true')
            }

        }
    });
    menuMobileCloseButton.on({
        'mousedown': function(event) {
            if (event.button == 0) {
                event.preventDefault();
                closeMenuMobile();
                menuMobileOpenButton.attr('aria-expanded','false')
            }
        },
        'keydown': function(event) {
            if (event.key == 'Enter' || event.key == ' ') {
                event.preventDefault();
                console.log("menuMobileCloseButton");
                closeMenuMobile();
                // menuMobileOpenButton.attr('aria-expanded','false')
                setTimeout(function() {
                    menuMobileOpenButton[0].focus();
                }, 200);
            }
        }
    });

    menuMobileLinkDropdown.on('mousedown', function(event) {
        event.preventDefault();
        if (event.button == 0) {
            toggleMenuMobileDropdown(event, $(this));
            if (jQuery(this).hasClass('is-active')) {
                closeWrapTabs();
                wrapTabs(jQuery(this).closest("li"));
                quitarControlTeclado();
                activarControlTeclado(jQuery(this));
                jQuery(this).attr('aria-expanded','true')
            } else {
                closeWrapTabs();
                quitarControlTeclado();
                jQuery(this).attr('aria-expanded','false')
                //console.log("se cerro el menu");
            }
        }
    });

    menuMobileLinkDropdown.on("keydown", function(event) {
        if (event.key == "Enter") {
            event.preventDefault();
            toggleMenuMobileDropdown(event, $(this));
            if (jQuery(this).hasClass('is-active')) {
                wrapTabs(jQuery(this).closest("li"));
                jQuery(this).closest('.c-menu-mobile-nav__item').find("a.c-menu-mobile-nav__sublink")[0].focus();
            }
        }
    });

    jQuery(document).on("keydown", function(e) {
        //console.log(e.key);
        if (e.key == "Escape") {
            //console.log("submenus abiertos");
            //console.log(jQuery(".js-menu-mobile-link-dropdown.is-active").length);
            if (menuMobile.hasClass('is-open')) {
                //console.log("ningun submenu abierto");
                closeMenuMobile();
                jQuery(menuMobileOpenButton)[0].focus();
                
            }
        }
    })
});