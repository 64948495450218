/**
 * Key Press
 */

$(function() {

    $(document).on('keyup', function(event) {
        if (event.key === 'Escape') {
            if (nearestStoreToggle.hasClass('is-active')) {
                closeNearestStore();
            } else if (menuMobile.hasClass('is-open')) {
                //closeMenuMobile();
                /*
                setTimeout(function() {
                    menuMobileOpenButton.focus();
                }, 200);
                */
            }
        }
    });
});