var radioButtons = ["#any-time", "#open-now"];
$(function() {

    if ($('#js-office-locator-map').length) {

        var sticky = new Sticky('.js-office-locator-map-wrapper');
        console.log(sticky);

        if (jQuery("input[name=time]").length) {
            jQuery("input[name=time]").on("keydown", function(event) {
                switch (event.key) {
                    case "ArrowRight":
                    case "ArrowLeft":
                    case "ArrowUp":
                    case "ArrowDown":
                        //changeSelectedItem(event);
                        break;

                }
            })
        }
    }
})

function changeSelectedItem(event) {
    event.preventDefault();
    var selectedItem = jQuery("[name=time]:checked").attr("id");
    console.log(selectedItem);
    var curItem = jQuery.inArray("#" + selectedItem, radioButtons);
    var nextItem = 0;
    switch (event.key) {
        case "ArrowRight":
        case "ArrowDown":
            nextItem = curItem + 1;
            break;
        case "ArrowLeft":
        case "ArrowUp":
            nextItem = curItem - 1;
            break;
    }
    if (nextItem < 0) {
        nextItem = radioButtons.length - 1;
    } else {
        if (nextItem >= radioButtons.length) {
            nextItem = 0;
        }
    }
    jQuery(radioButtons[nextItem]).prop("checked", true);
    jQuery(radioButtons[nextItem])[0].focus();
    //console.log(item);

    //console.log(position);
}