let navDesktopLinkDropdown = $('.js-nav-desktop-link-dropdown');
let navDesktopLinks = jQuery(".c-nav-desktop__link");
let navDesktopMenuItems = jQuery("li.c-nav-desktop__item");
/** 
 * Menu mobile
 */
function closeMainMenu(menuItem) {
    //jQuery(".c-nav-desktop__item").each(function(indice, item) {
    //var itemLink = jQuery(item).find('a.js-nav-desktop-link-dropdown');
    //console.log(itemLink);
    //return 0;
    //itemLink.removeClass('is-active');
    //var itemSublist = jQuery(item).find('.is-open');
    //itemSublist.removeClass('is-open');
    //;
    //});

    if (menuItem != null) {
        //console.log("menuitem is not null");
        //console.log(menuItem);
        var itemLink = jQuery(menuItem).find('.js-nav-desktop-link-dropdown');
        var itemSublist = jQuery(menuItem).find('.is-open');
        itemLink.removeClass('is-active');
        itemSublist.removeClass('is-open');
        menuItem.removeClass('block-active');
    }
    jQuery(".c-nav-desktop__item.block-active").removeClass('block-active')
        //closeWrapTabs();
}

function toogleNavDesktopLinkDropdown(event, $this) {
    event.preventDefault();
    navDesktopMenuItems.each(function(indice, el) {
        jQuery(el).removeClass("block-active");
    });
    if ($this.hasClass('is-active')) {
        $this.removeClass('is-active').attr("aria-expanded","false");
        $this.next('.c-nav-desktop__sublist-wrapper').removeClass('is-open');
    } else {
        navDesktopLinks.removeClass('is-active')
        $this.addClass('is-active').attr("aria-expanded","true");
        $('.c-nav-desktop__sublist-wrapper').removeClass('is-open');
        $this.next('.c-nav-desktop__sublist-wrapper').addClass('is-open');
        $this.closest("li").addClass("block-active");
        activeMainMenuItem(event, $this);
    }
}

function changeMainMenuItem(event) {
    if (jQuery(".c-nav-desktop__item.block-active").length || jQuery(".c-nav-desktop__link").is(":focus")) {
        //var curItem = jQuery(".c-nav-desktop__item.block-active");
        var newItem = null;
        var curItem = null;
        var tmp = jQuery(":focus").closest('.c-nav-desktop__item').length;
        if (tmp != 0) {
            curItem = jQuery(":focus").closest('.c-nav-desktop__item');
        } else {
            if (tmp == undefined || tmp == 0) {
                curItem = jQuery(".c-nav-desktop__item.block-active");
            }
        }

        switch (event.key) {
            case "ArrowLeft":
                newItem = jQuery(curItem).prev();
                if (jQuery(newItem).length == 0) {
                    newItem = jQuery(".c-nav-desktop__item:last-child");
                }
                break;
            case "ArrowRight":
                newItem = jQuery(curItem).next();
                if (jQuery(newItem).length == 0) {
                    newItem = jQuery(".c-nav-desktop__item:first-child");
                }
                break;
        }
        var newItemLink = newItem.find(" > a");
        if (newItemLink.hasClass('has-dropdown')) {
            //console.log("abrir menuitem");
            toogleNavDesktopLinkDropdown(event, newItemLink);
            //activeMainMenuItem(event, newItemLink.closest('li'));
            //newItemLink.trigger('click');
        } else {
            closeMainMenu(curItem);
            newItemLink[0].focus();
        }
        //console.log(newItem);
    }
}

function activeMainMenuItem(event, menuItem) {

    //console.log(event);


    switch (event.type) {
        case "mousedown":
            menuItem.trigger("click");

            break;
        case "keydown":
            menuItem[0].focus();
            break;
    }
    return false;
    /**
     * Disable siblings and hide submenu
     */
    console.log("active main menu");
    var activeItem = jQuery(".c-nav-desktop__item.block-active");
    //console.log(activeItem);


    console.log(activeItem[0] == menuItem[0]);
    if (activeItem[0] == menuItem[0]) {
        //console.log("se clickeo el mismo");
        /**
         * Se eligió el mismo elemento ya abierto => cerrar 
         */
        closeMainMenu();
    } else {

        /**
         * active current 'li' and showing submenu
         */
        console.log("showing menu");
        let linkdropdown = jQuery(menuItem).find('.js-nav-desktop-link-dropdown');
        console.log(linkdropdown);

        toogleNavDesktopLinkDropdown(event, linkdropdown);
        jQuery(menuItem).addClass('block-active');
        jQuery(linkdropdown)[0].focus();
        wrapTabs(jQuery(menuItem));
    }
    if (activeItem.length != 0) {

        jQuery(menuItem).siblings().each(function(index, item) {
            if (jQuery(item).hasClass('block-active')) {
                jQuery(item).removeClass('block-active');
            }
            var link = jQuery(item).find('a.js-nav-desktop-link-dropdown');
            toogleNavDesktopLinkDropdown(event, link);
            //jQuery(item).find('li.c-nav-desktop__item').removeClass('block-active');
        });
    }
}

$(function() {
    /*
        navDesktopLinkDropdown.on('click', function(event) {
            toogleNavDesktopLinkDropdown(event, $(this));
        });
    */
    navDesktopLinkDropdown.on("keydown", function(event) {
        if (event.key == "Enter") {
            toogleNavDesktopLinkDropdown(event, $(this));
        }
    });
    navDesktopLinkDropdown.on("click", function(event) {
        event.preventDefault();
    });
    navDesktopLinkDropdown.on('mousedown', function(event) {
        //console.log(event);
        switch (event.button) {
            case 0: //click izquierdo
                event.preventDefault();
                //console.log("click iz   uier");
                //event.preventDefault();
                toogleNavDesktopLinkDropdown(event, $(this));
                break;
            default:
                break;
        }
    });
    jQuery(document).on("click", function(event) {
        var jQuerytarget = jQuery(event.target);
        if (jQuery(".c-nav-desktop__list li.block-active").length) {
            if (!jQuerytarget.closest('.c-nav-desktop__list').length &&
                jQuery('.c-nav-desktop__list').is(":visible")) {
                closeMainMenu(jQuery(".c-nav-desktop__item.block-active"));
            }
        }
    });
    jQuery(document).on('keydown', function(e) {
        if (e.key == "Escape") {
            if (jQuery(".block-active").length) {
                closeMainMenu(jQuery(".c-nav-desktop__item.block-active"));
            }
        }
        if (e.key == "ArrowLeft" || e.key == "ArrowRight") {
            changeMainMenuItem(e);
        }
    });
});