// Imports

//=include jquery/dist/jquery.min.js
//=include jquery-validation/dist/jquery.validate.min.js
//=include jquery-mask-plugin/dist/jquery.mask.min.js
//=include sticky-js/dist/sticky.min.js
//=include intersection-observer/intersection-observer.js
//=include wicg-inert/dist/inert.min.js

//=include ../source/js/tools/tools.filter-input.js
//=include ../source/js/tools/tools.google-places-autocomplete.js
//=include ../source/js/tools/tools.trap-focus.js
//=include ../source/js/tools/tools.stop-transition-on-resize.js
//=include ../source/js/components/components.main-menu.js
//=include ../source/js/components/components.modal-turbo.js
//=include ../source/js/components/components.modal-desktop.js 
//=include ../source/js/components/handleObserver.js 
//=include ../source/js/tools/tools.go-to-element.js
//=include ../source/js/usabilidad.ae.js

//=include ../source/js/components/components.input-filters.js
//=include ../source/js/components/components.nearest-store.js
//=include ../source/js/components/components.menu-mobile.js
//=include ../source/js/components/components.nav-desktop.js
//=include ../source/js/components/components.teaser-bar.js
//=include ../source/js/components/components.key-press.js 
//=include ../source/js/components/components.sticky-config.js

//=include ../source/js/components/components.ancla-footer.js
//=include ../source/js/components/components.office-hours.js
//=include ../source/js/components/components.office-locator.js
//=include ../source/js/components/components.message-alert.js
//=include ../source/js/components/components.quote.js
//=include ../source/js/components/components.skip-content.js
//=include ../source/js/components/components.imgs.js
