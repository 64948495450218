$(function() {
    //console.log("MODAL TURBO");
    var modal_href = '#modal-turbo';
    var triggers_list = jQuery("a[href='"+modal_href+"']");
    jQuery.each(triggers_list,function(i,el){
        //console.log(el);
        var el_class = jQuery(el).attr('class');
        
        //console.log(el_class);
        if(typeof  el_class === undefined){
            jQuery(el).addClass('js-button-quote-turbo-trigger js-button-quote-turbo');

        }else{
            jQuery(el).addClass('js-button-quote-turbo-trigger js-button-quote-turbo-without-styles');

        }
        //jQuery(el).attr('aria-expanded',"false");
        jQuery(el).attr('aria-expanded',"false");
        jQuery(el).attr('aria-controls',"modalQuickQuoteTurbo");
        jQuery(el).attr('aria-label',"Get a quote online");
        jQuery(el).attr('data-name-product',jQuery("#modalQuickQuoteTurbo").attr('data-insurance'));
    });

    var buttonQuoteTurbo = $('.js-button-quote-turbo-trigger');
    var nameProductModal = $('.js-modal-turbo__name-product');
    var imageProductModal = $('.c-modal-turbo__img-product');
    jQuery.each(buttonQuoteTurbo,function(el,val){
        //console.log("MODAL TURBO QUOTE");
        //console.log(el);
        //console.log(val);

        //console.log(val);
        jQuery(val).on('click',function(e){
            e.preventDefault();
            e.stopPropagation();
            //console.log("clicled");
            var nameProductText =  $(val).data("nameProduct");
            //console.log("nameProductText" + nameProductText);
            var imageProduct =  imageProductModal.data("src");
            nameProductModal.text(nameProductText);
            imageProductModal.attr('src', imageProduct)
        });
    }); 
    /*
    buttonQuoteTurbo.on('click', function (e){
        e.preventDefault();
        console.log("clicled");
        var nameProductText =  $(this).data("nameProduct");
        console.log("nameProductText" + nameProductText);
        var imageProduct =  imageProductModal.data("src");
        nameProductModal.text(nameProductText);
        imageProductModal.attr('src', imageProduct) 
    })

     */
    //MyApp.form();
});